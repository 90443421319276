<template>
  <div class="hold-transition login-page" style="background-image:url(/assets/images/login-bg.jpg)">
    <div class="login-box">
      <a href="/"><img src="/assets/images/logo.svg" alt="Dotlines"></a>
      <p class="login-box-msg">Reset Password??</p>
      <form @submit.prevent="reset_pass()" method="POST" class="response-alert" novalidate>
        <div class="form-group mb-3">
          <label class="control-label"><i class="fas fa-lock"></i> Reset OTP Code</label>
          <div class="input-group">
            <input type="text" name="otp_code" v-model.trim="resetPassInfo.otp_code" class="form-control"
                   placeholder="Reset OTP Code" v-validate="'required'"
                   :class="{'has-error' : errors.has('otp_code')}">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-code"></span>
              </div>
            </div>
          </div>
          <div class="help text-danger" v-show="errors.has('otp_code')">
            {{ errors.first('otp_code') }}
          </div>
        </div>

        <div class="form-group mb-3">
          <label class="control-label"><i class="fas fa-lock"></i> New Password</label>
          <div class="input-group">
            <input type="password" name="password" v-model.trim="resetPassInfo.password" class="form-control"
                   placeholder="Password" v-validate="'required'" ref="password"
                   :class="{'has-error' : errors.has('password')}">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
          <div class="help text-danger" v-show="errors.has('password')">
            {{ errors.first('password') }}
          </div>
        </div>

        <div class="form-group mb-3">
          <label class="control-label"><i class="fas fa-lock"></i> Confirm Password</label>
          <div class="input-group">
            <input type="password" name="password_confirmation" v-model.trim="resetPassInfo.password_confirmation"
                   class="form-control" placeholder="Confirm Password" v-validate="'required|confirmed:password'"
                   data-vv-as="password" :class="{'has-error' : errors.has('password_confirmation')}">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
          <div class="help text-danger" v-show="errors.has('password_confirmation')">
            {{ errors.first('password_confirmation') }}
          </div>
        </div>
        <div class="row mb-2 align-items-center">
          <div class="col-6">

          </div>
          <div class="col-6 text-right forgot-pass">
            <router-link :to="{name: 'Login'}">
              Back to login <i class="fas fa-question-circle"></i>
            </router-link>
          </div>
        </div>
        <button type="submit" class="btn btn-common btn-block">Reset Password <i class="fas fa-arrow-right"></i>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import GuestLayout from '@/components/layouts/GuestLayoutComponent.vue';
import axios from 'axios';
import router from "@/router";
// import store from "@/store";

export default {
  components: {
    //
  },
  data: () => ({
    resetPassInfo: {
      send_via: '',
      mobile_or_email: ''
    },
  }),
  methods: {

    reset_pass: function (e) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let api_url = this.API_BASE_URL + '/auth/reset-password';
          axios({
            method: 'post',
            url: api_url,
            data: this.resetPassInfo
          }).then(function (response) {
            console.log('check response', response);
            if (response.status === 200) {
              router.push({'path': '/login'});
            } else {
              $(".response-alert").prepend($(
                '<div class="alert alert-danger alert-dismissible fade show" role="alert">\n' +
                '    Invalid username or password!\n' +
                '    <button type="button" class="close" data-dismiss="alert" aria-label="Close">\n' +
                '       <span aria-hidden="true">&times;</span>\n' +
                '    </button>\n' +
                '</div>'));
            }
          }).catch(function (error) {
            if (error.response) {
              $(".response-alert").prepend($(
                '<div class="alert alert-danger alert-dismissible fade show" role="alert">\n' +
                error.response.data.message +
                '    <button type="button" class="close" data-dismiss="alert" aria-label="Close">\n' +
                '       <span aria-hidden="true">&times;</span>\n' +
                '    </button>\n' +
                '</div>'));
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }

          }).finally(function () {
            _this.is_loading = false;
            _this.is_disabled = false;
            setTimeout(() => {
              $('.alert').fadeOut('slow');
            }, 5000);
          });
        }
      });
    },

  },
  mounted: function () {
    let params = this.$route.params;
    if (params !== undefined && Object.keys(params).length > 0) {
      this.resetPassInfo.send_via = params.userInfo.send_via;
      this.resetPassInfo.mobile_or_email = params.userInfo.mobile_or_email;
    } else {
      router.push({'path': '/login'});
    }
  },
  created() {
    this.$emit('update:layout', GuestLayout)
  }
}
</script>

<style scoped>
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: none !important;
  border-radius: 0.25rem;
}
</style>
